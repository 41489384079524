@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root {
  --dark: #181A20;
  --light-dark: #35383F;
  --blue: #246BFD;
  --red: #E55555;
  --gray: #949B9F;
  --white: #FFFFFF;
  --black: #000000;
}

::-webkit-scrollbar {
  display: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Almarai', 'Poppins', sans-serif;
  line-height: 1.5;
  color: var(--white);
  background-color: var(--dark);
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

